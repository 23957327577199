* {
  box-sizing: border-box;
}
html,
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
button,
input,
textarea,
p,
select,
blockquote,
th,
td,
img
{
  margin: 0;
  padding: 0;
  font-weight: 300;
  color: #000;
  font-size: 1em;
  word-break: keep-all;
  word-wrap: break-word;
}
html,
body {
  font-size: 16px;
  width: 100%;
  height: 100% !important;
  overflow-x: hidden;
  overscroll-behavior: contain;
}
body.active {
  overflow-y: hidden;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
  color: #000;
}
a:hover {
  text-decoration: none;
}
a:active {
  text-decoration: none;
}
table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}
img {
  display: block;
  border: 0;
}
button {
  background: transparent;
  border: 0;
  cursor: pointer;
  outline: 0;
}
input,
select,
textarea {
  border: 1px solid #aaa;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  outline: none;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.ant-upload-list-item-actions > a {
  display: none;
}
.ant-pagination-options {
  display: none;
}

.toastui-editor-contents img{margin: 0 !important;}
.toastui-editor-contents h1{margin:0 !important;padding:0 !important;font-size: 22px !important;font-weight: 400 !important;line-height: 33.5px !important;border:0 !important;color: #000 !important;}
.toastui-editor-contents h2{margin:0 !important;padding:0 !important;font-size: 18px !important;font-weight: 400 !important;line-height: 27px !important;border:0 !important;color: #727272 !important;}
.toastui-editor-contents h3{margin:0 !important;padding:0 !important;font-size: 16px !important;font-weight: 400 !important;line-height: 24px !important;border:0 !important;color: #727272 !important;}
.toastui-editor-contents h4{margin:0 !important;padding:0 !important;font-size: 13px !important;font-weight: 400 !important;line-height: 22px !important;border:0 !important;color: #727272 !important;}
.toastui-editor-contents h5{margin:0 !important;padding:0 !important;font-size: 11px !important;font-weight: 400 !important;line-height: 19px !important;border:0 !important;color: #727272 !important;}
.toastui-editor-contents h6{margin:0 !important;padding:0 !important;font-size: 10px !important;font-weight: 400 !important;line-height: 16px !important;border:0 !important;color: #727272 !important;}
.toastui-editor-contents p{margin:0 !important;padding:0 !important;font-size: 10px !important;font-weight: 400 !important;line-height: 16px !important;border:0 !important;color: #727272 !important;}
.toastui-editor-contents h1 strong{line-height: 33.5px !important;}
.toastui-editor-contents h2 strong{line-height: 27px !important;}
.toastui-editor-contents h3 strong{line-height: 24px !important;}
.toastui-editor-contents h4 strong{line-height: 22px !important;}
.toastui-editor-contents h5 strong{line-height: 19px !important;}
.toastui-editor-contents h6 strong{line-height: 16px !important;}
.toastui-editor-contents p strong{line-height: 16px !important;}